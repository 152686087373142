.report-container {
    margin: 50px auto;
    max-width: 600px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.report-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.report-container .back {
    color: black;
}


.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #5036f5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #381ee2;
}
