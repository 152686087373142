.login-container {
    width: 500px;
    height: 600px;
    flex-shrink: 0;
    background-color: rgba(255, 248, 248, 1);
    border-radius: 20px;
    border: 2px solid #060556;
    padding: 100px;
    position: absolute;
    top: 12%;
    

}
.login-container h2{
    text-align: center;
    font-size: 28px;
    color: #060556;
    position: fixed;
    top: 20%;
    margin: 0%;
   
   
}
.login-container .login-form {
    position: relative;
    width: 100%;
    height: 60%;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 20px;
  
}

.login-form input{
    position: relative;
    width: 100%;
    height: 35px;
    background-color: #F4EDED;
    outline: none;
    border: 2px solid #060556;
    box-shadow: 0px 4px 4px 0px rgba(6, 5, 86, 1 , 0.25);
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    padding: 20px 10px;
    

}
.login-form label{
    color: #060556;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
    display: block;
   
   
}

.login-form .icon {
    position: absolute;
    right: 13px;
    top: 70%;
    font-size: 16px;
    color: black;
    pointer-events: none;
    transform: translateY(-50%);
}

.login-container .extra-options{
    display: flex;
    justify-content: flex-end;
    font-size: 15px;
    margin: -10px 0 50px;
    color: #060556;
}

.extra-options a {
    text-decoration: none;
}

.extra-options a:hover {
    text-decoration: underline;
}


.login-container button {
    width: 100%;
    height: 50px;
    background-color: #060556;
    border: none;
    outline: none;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(6, 5, 86, 1 , 0.25);
    cursor: pointer;
    font-size: 25px;
    color: #FFF;
    margin-top: 25px;
    
   

}
.login-container .redirect {
    font-size: 18px;
    text-align: center;
    margin: 10px 0 10px;


}
.error-message {
    color: red;
    margin-bottom: 1rem;
}

.close-button {
   position: fixed;
   top: 90px;
   right: 540px;
   cursor: pointer;
   font-size: 25px;
   color: #060556;

  }