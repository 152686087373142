body {
    overflow-x: hidden;
    background: linear-gradient(to right, #FFFFFF, #E0E0E0);
    color: #000000;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .home-header {
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      position: relative;
    }
    
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1200px;
    }
    
    .logo {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      margin-right: auto; /* Move o logotipo para a esquerda */
    }
    
    .nav-links {
      list-style-type: none;
      display: flex;
      gap: 20px;
      margin: 0;
      padding: 10px;
    }
    
    .nav-links li {
      cursor: pointer;
      color: #000;
    }
    
    .get-started-btn {
      
      background-color: #000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 20px;
      margin-top: -10px;
      margin-left: 10px;
      margin-right: 20px;
      font-size: 12px; 
      transition: all 0.3s ease; 
    }
    
    .get-started-btn:hover {
      background-color: #333; 
    }
  
    .main-content {
      padding: 40px 20px;
      max-width: 1280px;
      text-align: center;
      margin: 0 auto;
      padding: 2rem;
    }
    
    .featured-article {
      margin-bottom: 40px;
    }
    
    .featured-article h1 {
      font-size: 36px;
      margin-bottom: 20px;
    }
    
    .featured-article p {
      font-size: 18px;
      margin-bottom: 20px;
      color: #555;
    }
    
    .read-more-btn {
      background-color: #000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 20px;
    }
    .read-more-btn:hover {
      background-color: #333; 
    }
  
    
    
    
    