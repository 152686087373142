.content {
    background-color: #f8f8f8;
}

.content h2 {
    padding: 10px;
}

.content .back {
    margin-left: 10px;
}


.container {
    background-color: #f8f8f8;
    padding: 0.8em;
    margin-bottom: 1em;
    align-items: center;
}

.label-avatar {
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.label-avatar input {
    display: none;
}

.label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all 0.5s;
}

.label-avatar span:hover {
    opacity: 1;
    transform: scale(1.4);
}

.form-profile img {
    margin-bottom: 1em;
    border-radius: 50%;
    object-fit: cover;
}

.form-profile label {
    margin-bottom: 0.5em;
    font-size: 1.4em;
    
}

.form-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-profile input, textarea, select {
    margin-bottom: 1em;
    padding: 0.7em;
    border: 0;
    border-radius: 5px;
    width: 500px;
    max-width: 700px;
}


button {
    padding: 8px 20px;
    border: none;
    margin-bottom: 10px;
    font-size: larger;
    background-color: #060556;
    border-radius: 4px;
    color: #f8f8f8;
    transition: all 0.2s;
}


