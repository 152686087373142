.container {
    width: 500px;
    height: 600px;
    flex-shrink: 0;
    background-color: rgba(255, 248, 248, 1);
    border-radius: 20px;
    border: 2px solid #060556;
    padding: 100px;
    position: absolute;
    top: 12%;
    

   

}
.container h1{
    text-align-last: center;
    font-size: 30px;
    color: #060556;
    position: fixed;
    top: 17%;
    margin: 0%;
   
    
   
   
}

.container .form-group {
    position: relative;
    width: 100%;
    height: 60%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 20px;
   
   
  
}


.form-group input{
    position: relative;
    width: 100%;
    height: 35px;
    background-color: #F4EDED;
    outline: none;
    border: 2px solid #060556;
    box-shadow: 0px 4px 4px 0px rgba(6, 5, 86, 1 , 0.25);
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    padding: 20px 10px;
   
    

    

}
.form-group label{
    color: #060556;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
    display: block;
   
   
}
.form-group .icon {
    position: absolute;
    right: 13px;
    top: 60%;
    font-size: 16px;
    color: black;
    pointer-events: none;
    cursor: pointer;
    
   
}
 

.container button {
    width: 100%;
    height: 50px;
    background-color: #060556;
    border: none;
    outline: none;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(6, 5, 86, 1 , 0.25);
    cursor: pointer;
    font-size: 25px;
    color: #FFF;
    margin-top: 20px;
   

}
.container .login-redirect {
    font-size: 20px;
    text-align: center;
    margin: 10px 0 10px;

}
.login-redirect span a {
    color: #050384;
    

}


  
  .error-message {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
   
  }

.form-input.error {
    border:  1px solid red;
    background-color: #ffe6e6; 
  }

  .container .button-close{
    position: fixed;
    top: 90px;
    right: 540px;
    cursor: pointer;
    font-size: 25px;
    color: #060556;
    height: 45px;
  }