/* Layout geral da página */ 
.admin-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f4f7fc;
    font-family: 'Arial', sans-serif;
    height: 100vh;
    box-sizing: border-box;
  }
  
  .sidebar {
    width: 20%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo na barra lateral */
  }
  
  .sidebar img {
    width: 80px; /* Reduz o tamanho da imagem */
    height: 80px; /* Mantém a altura proporcional */
    border-radius: 50%; /* Torna a imagem redonda */
    margin-bottom: 20px; /* Espaço entre a imagem e o restante do conteúdo */
  }
  
  .sidebar h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sidebar p {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Estilo para a área de denúncias (à direita) */
  .denuncias-area {
    width: 75%;
  }
  
  .filtros {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .filtros label {
    font-size: 16px;
    margin-right: 10px;
    align-self: center;
  }
  
  .filtros select {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 200px;
  }
  
  /* Estilo da tabela */
  .denuncias-tabela {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .denuncias-tabela th,
  .denuncias-tabela td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
  }
  
  .denuncias-tabela th {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
  }
  
  .denuncias-tabela tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .denuncias-tabela tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s; /* Efeito de transição */
  }
  
  .denuncias-tabela a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .denuncias-tabela a:hover {
    text-decoration: underline;
  }
  .btn-ocultar {
    background-color: #ff4d4d;
    color: white; 
    padding: 10px 15px; 
    border: none; 
    border-radius: 5px; 
    font-size: 16px; 
    cursor: pointer; 
    transition: background-color 0.3s, transform 0.2s; 
  }
  
  .btn-ocultar:hover {
    background-color: #e60000;
    transform: translateY(-2px); 
  }
  
  .btn-ocultar:active {
    transform: translateY(0); 
  }
  
  /* Estilos do Modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Para garantir que fique acima de outros elementos */
  }
  
  .modal-content {
    padding: 20px;
    max-width: 400px; /* Ajuste a largura máxima do modal */
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
  }
  
  textarea {
    width: 100%; /* Defina a largura da caixa de texto para ocupar 100% do modal */
    box-sizing: border-box; /* Para incluir padding e bordas na largura total */
    resize: vertical; /* Permitir redimensionar verticalmente */
  }
  
  .modal-content h3 {
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  
  
  .btn-confirmar {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-cancelar {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-confirmar:hover, .btn-cancelar:hover {
    opacity: 0.8;
  }
  .btn-banimento {
    background-color: #f39c12;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .btn-banimento:hover {
    background-color: #e67e22;
  }
  
  .mensagem-confirmacao {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  span {
    font-weight: bold;
    color: #333;
  }
  
  
  /* Estilo para mobile */
  @media (max-width: 768px) {
    .admin-page {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .denuncias-area {
      width: 100%;
    }
  }