*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body, html, #root {
  height: 100%;
}

body {
  font: 14px;
  font-family: sans-serif;
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}